import React, { Component } from "react"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import styled from "styled-components"
import Img from "gatsby-image"
import FsLightbox from "fslightbox-react"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Grid = styled.div`
  margin-top: 40vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`

const Container = styled.div`
  flex-basis: 16.6%;
  height: 200px;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  /*Media Queries*/
  @media (max-width: 1000px) {
    flex-basis: 25%;
  }

  @media (max-width: 850px) {
    flex-basis: 33.33%;
  }
  @media (max-width: 600px) {
    flex-basis: 50%;
  }
`
const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-out;
  transform: scale(1);

  ${Container}:hover & {
    transform: scale(1.1);
  }
`

const ImgWrapper = styled.div`
  height: 100%;
  transition: 0.3s ease-out;
  transform: scale(1);

  ${Container}:hover & {
    transform: scale(1.1);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ease-out;
    transform: scale(1);
  }
`

class BillboardsPage extends Component {
  state = {
    imageUrls: null,
    photoIndex: 0,
    isOpen: false,
  }

  componentDidMount() {
    //Set the gallery to be the first title returned
    const billboardsData = this.props.data.allWordpressPost.edges
    this.setState({
      imageUrls: billboardsData[0].node.acf.gallery.map(
        object => object.source_url
      ),
      photoIndex: 0,
      isOpen: false,
    })
  }
  openLightbox = i => {
    this.setState({ isOpen: !this.state.isOpen, photoIndex: i })
  }
  render() {
    const billboardsData = this.props.data.allWordpressPost.edges
    const { imageUrls, photoIndex, isOpen } = this.state
    return (
      <Layout>
        <SEO title="Billboards" />
        <PageHeader>Billboards</PageHeader>

        <Grid>
          {billboardsData[0].node.acf.gallery.map((billboard, index) => (
            <>
              <Container key={index} onClick={() => this.openLightbox(index)}>
                {/* <Cover src={photo.source_url} /> */}
                <ImgWrapper>
                  <Img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transform: "scale(1)",
                      ":hover": {
                        transform: "scale(1.)",
                      },
                    }}
                    fadeIn
                    objectFit="cover"
                    fluid={billboard.localFile.childImageSharp.fluid}
                  />
                </ImgWrapper>
              </Container>
            </>
          ))}
        </Grid>
        {imageUrls && (
          <FsLightbox
            key={imageUrls}
            toggler={isOpen}
            slide={photoIndex + 1}
            sources={imageUrls}
            type="image"
          />
        )}
      </Layout>
    )
  }
}

export default BillboardsPage

export const billboardsQuery = graphql`
  query($category: String = "Billboards") {
    allWordpressPost(
      sort: {fields: date, order: DESC}
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          acf {
            gallery {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
